<template>
    <workspace-wrapper>
        <template #workspace>
            <PlinkoWorkspace :bet="stateData.currentSpinResult.bet" :color="stateData.currentSpinResult.color"
                :totalWin="stateData.currentSpinResult.totalWin" />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import PlinkoWorkspace from "./PlinkoWorkspace.vue";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        bet: null,
                        color: null,
                        totalWin: null
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        PlinkoWorkspace
    }
}
</script>
<style scoped></style>
