<template>
    <div class="main">
        <img :src="button" alt="картинки нет" />
        <h1 class="multiplier">{{ multiplier }}</h1>
    </div>
</template>

<script>
export default {
    props: {
        bet: {
            type: Number,
            required: true,
        },
        totalWin: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    },
    computed: {
        button() {
            return require(`@/assets/games/mini/plinko/` + this.color + `.png`);
        },
        multiplier() {
            let multiplier = this.totalWin / this.bet;
            return multiplier.toFixed(1);
        }
    },
    methods: {

    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 150);
        this.$store.dispatch('workspace/setWidth', 200);
    },
}
</script>

<style scoped>
.main {
    margin: 0 auto;
    width: 280px;
    height: 150px;
    display: flex;
    justify-content: center;
}

.multiplier {
    position: absolute;
    top: 55px;
    font-size: 25px;
    font-weight: 700;
    color: white
}
</style>
